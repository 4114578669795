import React, { useState, useEffect } from 'react';

import { client as apollo } from 'cccisd-apollo';
import { SessionDataEntry } from 'cccisd-laravel-assignment';

import clientGroupsQuery from './clientGroups.graphql';

var Fortress = window.cccisd.fortress;

function SessionData(props) {
    const [clientGroups, setClientGroups] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        initializeData();
    }, []);

    async function initializeData() {
        const clientGroupsList = await getClientGroups();

        await setClientGroups(clientGroupsList.roles.clientList);

        setIsLoading(false);
    }

    async function getClientGroups() {
        let pawnId = Fortress.user.acting.id;
        const res = await apollo.query({
            query: clientGroupsQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        return res.data;
    }

    function formatClientGroups(roles) {
        return roles.map(({ pawn: { pawnId, pawnHash }, fields: { clientLabel }, childRoles: { participantList } }) => {
            return {
                groupId: pawnId,
                pawnId,
                pawnHash,
                label: clientLabel,
                flowProps: getFlowProps(participantList),
            };
        });
    }

    function formatParticipantList(list) {
        return list.map(item => {
            return {
                pawnId: item.pawn.pawnId,
                pawnHash: item.pawn.pawnHash,
                name: item.fields.participantId,
            };
        });
    }

    function getFlowProps(participantList) {
        return {
            userLists: {
                participants_list: formatParticipantList(participantList),
            },
        };
    }

    return (
        !isLoading && (
            <SessionDataEntry
                {...props}
                questprojectIds={[13]}
                groups={formatClientGroups(clientGroups)}
                title="Session Data"
                description="Enter contact and fidelity data gathered during child group sessions."
            />
        )
    );
}

export default SessionData;
